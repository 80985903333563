// src/Login.js
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ErrorAlert from './components/ErrorAlert';
import { API_URL } from './config';

const Login = () => {
  const [stands, setStands] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`${API_URL}/stands`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error al obtener la lista de stands');
        }
        return response.json();
      })
      .then((data) => setStands(data))
      .catch((err) => setError(err));
  }, []);

  if (error) {
    return (
      <div className="container mx-auto p-6">
        <ErrorAlert message={error.message} />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl mb-6">Selecciona un stand</h1>
      <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {stands.map((stand) => (
          <li key={stand.id} className="text-center">
            <Link to={`/scanner/${stand.id}`}>
              <img className="mx-auto h-24 w-96 object-contain" src={`${API_URL}/uploads/stands/${stand.logo}`} alt={stand.name} />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Login;
