import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { QrReader } from "react-qr-reader";
import ErrorAlert from "./components/ErrorAlert";

import { API_URL } from "./config";

const QRScanner = () => {
  const { standId } = useParams();
  const [stand, setStand] = useState(null);
  const [error, setError] = useState(null);
  const [scanMessage, setScanMessage] = useState(null);
  const [lastScan, setLastScan] = useState({ data: null, time: 0 });
  const [scanning, setScanning] = useState(false);

  useEffect(() => {
    fetch(`${API_URL}/stands/${standId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al obtener la información del stand");
        }
        return response.json();
      })
      .then((data) => setStand(data))
      .catch((err) => setError(err));
  }, [standId]);

  if (error) {
    return (
      <div className="container mx-auto p-6">
        <ErrorAlert message={error.message} />
      </div>
    );
  }

  if (!stand) {
    return <p>Cargando información del stand...</p>;
  }

  const handleScanButtonClick = () => {
    setScanning(!scanning);
  };

  const handleScan = (result, error) => {
    const currentTime = Date.now();
    const timeSinceLastScan = currentTime - lastScan.time;
    if (
      !!result &&
      result.text.split("/").pop() !== lastScan.qrPath &&
      timeSinceLastScan >= 5000
    ) {
      const qrPath = result.text.split("/").pop();
      if (qrPath.match(/^.*$/)) {
        setLastScan({ data: result.text, qrPath, time: currentTime });

        fetch(`${API_URL}/scanQR`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ id_stand: standId, folio: qrPath }),
        })
          .then((response) => {
            if (!response.ok) {
              return response.json().then((error) => {
                throw new Error(
                  error.message || "Error al registrar el escaneo"
                );
              });
            }
            return response.json();
          })
          .then((result) => {
            setScanMessage({ success: true, message: "Escaneo exitoso" });
            setTimeout(() => setScanMessage(null), 3000);
            setScanning(false);
          })
          .catch((err) => {
            setScanMessage({ success: false, message: err.message });
            setTimeout(() => setScanMessage(null), 3000);
          });
      } else {
        setScanMessage({
          success: false,
          message: "El formato del QR no es válido",
        });
        setTimeout(() => setScanMessage(null), 3000);
      }
    }

    if (!!error) {
      //setScanMessage({ success: false, message: "Error al escanear" });
      //setTimeout(() => setScanMessage(null), 3000);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <div className="mb-4 text-center">
        <h1 className="text-2xl mb-2">Escanear QR para {stand.name}</h1>
        <img
          className="mx-auto h-16 w-16 object-contain"
          src={`${API_URL}/uploads/stands/${stand.logo}`}
          alt={stand.name}
        />
      </div>

      {scanning ? (
        <QrReader
          delay={300}
          constraints={{
            facingMode: "environment",
          }}
          onResult={handleScan}
          style={{ width: "100%" }}
        />
      ) : null}
      <div className="flex justify-center items-center mt-4">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleScanButtonClick}
        >
          {scanning ? "Detener escaneo" : "Iniciar escaneo"}
        </button>
      </div>

      {scanMessage && (
        <div
          className={`mt-4 p-4 ${
            scanMessage.success ? "bg-green-500" : "bg-red-500"
          } text-white text-center rounded`}
        >
          {scanMessage.message}
        </div>
      )}
    </div>
  );
};

export default QRScanner;
